<template>
  <div>
    <div
      class="top-0 flex w-full items-center gap-2.5 bg-white py-2.5"
      :class="[search && 'sticky']"
    >
      <form
        class="flex w-full border"
        :class="
          errorMessage ? 'border-semantic-red-700' : 'border-neutral-black-300'
        "
        @submit.prevent="goToSearchPage"
      >
        <div class="relative flex-auto">
          <input
            ref="inputEl"
            v-model="search"
            name="search"
            :placeholder="$t('search')"
            autocomplete="off"
            class="h-10 w-full pl-2.5 pr-8 ui-text-l text-neutral-black-300 placeholder-neutral-black-300 focus:outline-none"
            @keydown.esc="$emit('close')"
            @focus="handleFocus"
            @blur="handleBlur"
          />
          <button
            v-if="search"
            type="button"
            class="absolute right-2.5 top-1/2 inline-flex size-4 -translate-y-1/2 items-center justify-center"
            :class="errorMessage && 'text-semantic-red-700'"
            :aria-label="$t('clearInput')"
            @click="clearSearch"
          >
            <UiIcon name="close" class="size-4" />
          </button>
        </div>
        <button
          :type="isFocused || search ? 'submit' : 'button'"
          :class="
            cn({
              'border-l border-neutral-black-300 bg-peach-600':
                isFocused || search,
              'border-border-semantic-red-700': errorMessage,
            })
          "
          :aria-label="$t('search')"
          class="inline-flex h-10 w-10.5 shrink-0 items-center justify-center"
        >
          <UiIcon name="search" class="size-6" />
        </button>
      </form>
      <button
        v-if="isFocused || search"
        type="button"
        class="text-[10px] uppercase text-neutral-black-300"
        @click="search = ''"
      >
        {{ $t("cancel") }}
      </button>
    </div>
    <SharedFieldHint v-if="errorMessage" :message="errorMessage" error />
    <CommonAppHeaderSearchResult
      v-if="search && !errorMessage"
      class="py-7.5"
      :search="search"
      :list-data="listData"
      :loading="loading"
      :count="count"
      @result-click="onResultItemClick"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { SearchProduct } from "ecom-types";

const inputEl = ref<HTMLInputElement>();
const searchForm = ref(null);
const isFocused = ref(false);
const emit = defineEmits([
  "close",
  "setSearchLength",
  "setListDataLength",
  "dataChange",
  "result-item-click",
  "goToSearchPage",
]);

const { loading, listData, search, count, errorMessage, goToSearchPage } =
  useSearch();

onClickOutside(searchForm, () => {
  setTimeout(() => {
    emit("close");
  }, 0);
});

function clearSearch() {
  search.value = "";
  if (inputEl.value) {
    inputEl.value.focus();
  }
}

function handleFocus() {
  isFocused.value = true;
}
function handleBlur() {
  isFocused.value = false;
}

function onResultItemClick(product: SearchProduct) {
  emit("result-item-click", product);
}
</script>
